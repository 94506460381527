import { useRouter } from "next/router";
import LinkWrapper from "../LinkWrapper";
import FaChevronRight from "../SVG/Fontawesome/FaChevronRight";
import PostPurchaseListItem from "./PostPurchaseListItem";
import { IMyCourseList } from "./interfaces";
import { DEEPLINK, STATIC_CDN } from "@/config/constants";
import { generateDeeplink } from "@/helpers/api/service";

import ImageBanner from "../CourseWidget/ImageBanner";

const PostPurchaseList = (props: {
  courses: {
    widget_data?: IMyCourseList;
  }
}) => {
  const router = useRouter();
  const courses = props.courses;

  if (!courses?.widget_data) return null;

  const handleBannerClick = async () => {
    const deeplink = `doubtnutapp://library_tab?tag=my_course`;
    try {
      const response = await generateDeeplink({ deeplink });
      window.open(response.deeplink);
    } catch (e) {
      window.open(DEEPLINK.APP_DEFAULT);
    }
  }

  return (
    <div className="w-full">
      <h3 className="mt-0">{courses.widget_data.title}</h3>
      <div onClick={handleBannerClick}>
        <ImageBanner
          className="cursor-pointer"
          data={{
            imgUrl: `${STATIC_CDN}/web_banners/course-small-banner.webp`,
            alt: "courses on app",
          }} />
      </div>
      <div className="flex flex-wrap  gap-4  mb-4">
        {courses.widget_data.items.map((course, i) => {
          return (
            <PostPurchaseListItem
              key={i.toString()}
              assortmentId={course.assortment_id}
              title={course.title}
              subtitle={course.sub_title}
              bulletPoint={course.bullet_point}
              progress={course.progress}
            />
          );
        })}
        {
          router.pathname !== "/my-course" &&
          <div className="m-auto">
            <LinkWrapper href="/my-course" className="btn-tertiary !no-underline">
              View all Courses <FaChevronRight />
            </LinkWrapper>
          </div>
        }
      </div>
    </div >
  );
};

export default PostPurchaseList;
