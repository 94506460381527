import { PAGE } from "@/config/enums";
import { SWW } from "@/config/toast";
import { getInterceptorActions } from "@/helpers/api/service";
import { selectDeferActionPayload, selectOffCanvasAllActionsCompleted, setDeferActionPayload, updateOffCanvasView } from "@/store/slices/offCanvas";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PostPurchaseListItem = (props: {
  assortmentId: number;
  title: string;
  subtitle: string;
  bulletPoint: string;
  progress?: string;
}) => {
  const dispatch = useDispatch();
  const allActionsCompleted = useSelector(selectOffCanvasAllActionsCompleted);
  const deferActionPayload = useSelector(selectDeferActionPayload);

  const openLink = (href: string) => {
    window.open(href, "_self");
  }

  const handleCourseClick = async (href: string) => {
    try{
      const response = await getInterceptorActions(PAGE.MY_COURSE);
      const actions = response.data?.modal_list;
      if (!actions?.length) {
        openLink(href);
        return;
      }
      dispatch(setDeferActionPayload({ href, assortmentId: props.assortmentId }));
      dispatch(updateOffCanvasView(actions))
    }catch(e: any){
      toast.error(e.message || SWW);
    }
  }

  useEffect(() => {
    if(!allActionsCompleted || !deferActionPayload?.href || deferActionPayload?.assortmentId !== props.assortmentId) return;

    openLink(deferActionPayload.href);
  }, [allActionsCompleted, deferActionPayload])

  return (
    <div className="w-full sm:pb-8 h-auto flex flex-wrap card">
      <div className="md:w-2/3 w-full">
        <div>
          <h4 className={`my-0 font-medium text-base`}>{props.title}</h4>
          <p className="font-normal">{props.subtitle}</p>
        </div>
        <button
          onClick={() => handleCourseClick(`/my-course/${props.assortmentId}`)}
          className="w-full flex flex-col justify-between cursor-pointer h-full no-underline"
        >
          <div className="w-full mt-2 text-zinc-800">
            <p className="link text-left px-0 my-0">{props.bulletPoint}</p>
            <div className="relative h-4 bg-green-300 rounded-full">
              <div
                className={`absolute h-4 left-0 top-0 bg-green-600 rounded-full animate-pulse`}
                style={{ width: props.progress || "0%" }}
              ></div>
              <span className={`absolute text-xs inset-x-1/2 font-normal`}>
                {props.progress || "0%"}
              </span>
            </div>
          </div>
        </button>
      </div>

      <div className="md:gap-4 gap-0 grid md:grid-cols-1 grid-cols-2 md:w-1/3 w-full  mt-4 items-start lg:px-16">
        <button
          onClick={() => handleCourseClick(`/my-course/${props.assortmentId}/all-classes`)}
          className="w-full !no-underline btn-primary text-center whitespace-nowrap md:rounded-md rounded-l-md rounded-r-none"
        >
          All Classes
        </button>
        <button
          onClick={() => handleCourseClick(`/my-course/${props.assortmentId}/upcoming-classes`)}
          className="btn-secondary w-full text-center !no-underline text-sm p-3 whitespace-nowrap md:rounded-md rounded-r-md rounded-l-none"
        >
          Upcoming Classes
        </button>
      </div>
    </div>
  );
};

export default PostPurchaseListItem;
